<template>
    <div>
          <v-bottom-sheet v-model="sheet" >
     
              <v-sheet  :height="height">
                <div class="text-center closeSheetBtnDiv">
                        <v-btn
                  class="myBottomSheetBtn" icon dark
                  @click="sheet = !sheet"
                ><v-icon color="white">mdi-close</v-icon>  </v-btn>
                </div>

                 <v-row justify="center">
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                    <tr  v-for="(list,i) in menu" :key="list.text" @click="showLink(list.link)">
                                        <td class="text-center" style="color: #379392">
                                            <v-list>

                                            <v-list-item >
                                                <v-list-item-avatar>
                                                    <v-icon size="40" color="secondary">{{list.icon}}</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <span >{{list.text}}</span>
                                                </v-list-item-content>
                                                <v-list-item-action></v-list-item-action>
                                            </v-list-item>
                                            </v-list>

                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                   



                 </v-row>
              </v-sheet>
    </v-bottom-sheet>
   
    </div>
</template>
<script>
import {sheetMixin} from '@/mixins/Sheet'

export default {
    inject:["Bus"],
    props:['menu','icon','left_link','property_slug','height'],
    mixins:[sheetMixin],
    data(){
        return{
            sheet:false,
    
        }
    },
    methods:{
      showLink(t){
        this.Bus.$emit('open-bottom-sheet',false)
        switch(t){
            case 'property_offer_list':
                return this.$router.push({name:t,params:{slug:this.property_slug}});
                break;
            case 'property_inspection_list':
                return this.$router.push({name:t,params:{slug:this.property_slug}});
                break;
            case 'add_property':
                return this.$router.push({name:t,params:{type:'New'}});
            break;
             case 'add_property_two':
                return this.$router.push({name:'add_property',params:{type:this.property_slug}});
            break;
            default:
                return  this.$router.push({name:t});
            break;
                
                
        }
          
   
      }
    },
    created(){
        this.Bus.$on("open-bottom-sheet",(sheet)=>{
            this.sheet = sheet;
        })
    }
}
</script>
<style  scoped>
        .closeSheetBtnDiv{
 margin-bottom: 0px;
 
}
.myBottomSheetBtn{
  width:40px;
  height: 40px;
  top:-20px;
  background-color: #379392;
  border-radius: 50%;
}
</style>