<template>
  <div>
    <v-bottom-navigation height="60" :value="bottombar_active_btn" class="mynavbar" :fixed=true app color="primary"  elevation="0">
      <v-btn class="mynavbar_btn" @click="goLink('home')">
<!--        <span>Home</span>-->
<!--        <span v-if="$route.name === 'home' "> <v-icon size="15">mdi-music-note-whole</v-icon></span>-->
        <v-icon :size="icon_size">mdi-home-outline</v-icon>
      </v-btn>
        <v-btn class="mynavbar_btn" @click="goLink('property_list')">
<!--        <span>Properties</span>-->
<!--          <span v-if="$route.name === 'properties' "> <v-icon size="15">mdi-music-note-whole</v-icon></span>-->
        <v-icon :size="icon_size">mdi-home-city-outline</v-icon>
      </v-btn>
      <!-- <v-btn class="mynavbar_btn" @click="goLink('search')">
        <span>Search</span>
        <v-icon>mdi-magnify </v-icon>
      </v-btn> -->
       <!-- <v-tooltip top>
        <template v-slot:activator="{ on }"> -->
          <v-btn  class="myfab" absolute  color="secondary"  dark @click="open_Bottom_sheet()">
            <v-icon color="white">mdi-plus</v-icon>
          </v-btn>
        <!-- </template> -->
        <!-- <span>Add Property</span> -->
      <!-- </v-tooltip> -->
      <v-btn class="mynavbar_btn" @click="goLink('connect_list')">
<!--        <span>Connect</span>-->
<!--        <span v-if="$route.name === 'connect' "> <v-icon size="15">mdi-music-note-whole</v-icon></span>-->
        <v-icon :size="icon_size">mdi-account-supervisor-outline </v-icon>
      </v-btn>
     
      <v-btn class="mynavbar_btn" @click="goLink('promotion_list')">
<!--        <span v-if="$route.name === 'promote' "> <v-icon size="15">mdi-music-note-whole</v-icon></span>-->
        <v-icon :size="icon_size">mdi-signal-cellular-outline</v-icon>
      </v-btn>
      <!-- <v-btn class="mynavbar_btn" @click="goLink('requestform')">
        <span>Request</span>
        <v-icon>mdi-message-outline</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
    <my-bottom-sheet height="180" :menu="bottom_sheet_menu"></my-bottom-sheet>
          <!-- <v-bottom-sheet v-model="sheet" >
     
      <v-sheet  height="250px">
        <div class="text-center closeSheetBtnDiv">
                <v-btn
          class="myBottomSheetBtn" icon dark
          @click="sheet = !sheet"
        ><v-icon color="white">mdi-close</v-icon>  </v-btn>
        </div>
    
        <div class="pl-5 pr-5 pb-5">
          <v-list>
                <v-list-item  active-class="highlighted" v-for="(list,i) in bottom_sheet_menu" :key="i">
                    <v-list-item-action>
                      <v-icon>{{list.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title >
                        {{list.text}}
                      </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
          </v-list>
        </div>
      </v-sheet>
    </v-bottom-sheet> -->
  </div>
</template>
<script>
import {sheetMixin} from '@/mixins/Sheet'
  export default {
    inject:["Bus"],
      mixins:[sheetMixin],
    
    data() {
      return {
        // bottombar_active_btn:0,
        // sheet:false,
        icon_size:"25",
            bottom_sheet_menu:[
               {text:"Add Property",icon:"mdi-home-plus-outline",link:"add_property"},
              {text:"Add client Request",icon:"mdi-message-plus-outline",link:"property_request"},
               
            ],
      }
    },
    computed: {
      bottombar_active_btn() {
        switch (this.$route.name) {
          case 'home':
            return 0;
            break;
          case 'property_list':
            return 1;
            break;
          case 'connect_list':
            return 3;
            break;
          case 'promotion_list':
            return 4;
            break;

          // default:
          //   return 0;
          //   break;
        }
      },

    },
    methods: {
          open_Bottom_sheet(){
              this.Bus.$emit('open-bottom-sheet',true);
          },
      goLink(link) {
          if(this.$route.name !== link) {
              switch(link){
                  case 'property_list':
                      this.$store.dispatch("propertyListTypeAction",'all');
                      break;
                  case 'connect_list':
                      this.$store.dispatch("agentListTypeAction",'all');
                      break;
              }
              
              return this.$router.push({
                  name: link,
              })
          }
      
      },
    },
  }
</script>
<style scoped>
  .myfab {
     /*top:10px;*/
    border-radius: 5px !important;
    width: 54px;
    height: 44px !important;
    max-width: 44px !important;
    min-width: 54px !important;
    margin: auto 0;
    /*min-width: 56px !important;*/
    /* background:green; */
    color: #fff;
    /* color:white; */
  }
  /* .v-btn--active{

  } */
  .theme--light.v-bottom-navigation .mynavbar_btn:not(.v-btn--active) {
    color: rgba(0, 0, 0, 0.50) !important;
  }

</style>